import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: "/login",
    component: () => import('@/views/login/index.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue'),
      },
      {
        path: '/SelectOrgnazation',
        name: 'SelectOrgnazation',
        component: () => import('@/views/login/SelectOrgnazation.vue'),
      },
      {
        path: '/CreateNewOrganization',
        name: 'CreateNewOrganization',
        component: () => import('@/views/login/CreateNewOrganization.vue'),
      },
      {
        path: '/SelectUnion',
        name: 'SelectUnion',
        component: () => import('@/views/login/SelectUnion.vue'),
      },
      {
        path: '/Completed',
        name: 'Completed',
        component: () => import('@/views/login/Completed.vue'),
      },
      {
        path: '/BusinessCreatorInformation',
        name: 'BusinessCreatorInformation',
        component: () => import('@/views/login/BusinessCreatorInformation.vue'),
      },
      {
        path: '/CompanyInformation',
        name: 'CompanyInformation',
        component: () => import('@/views/login/CompanyInformation.vue'),
      },
      {
        path: '/AwaitingApproval',
        name: 'AwaitingApproval',
        component: () => import('@/views/login/AwaitingApproval.vue'),
      }
    ]
  },
  {
    path: '/home',
    name: '/home',
    redirect: "/Homepage",
    component: () => import('@/views/home.vue'),
    children: [
      {
        path: '/Homepage',
        name: 'Homepage',
        component: () => import('@/views/Homepage/Homepage.vue'),
      },
      {
        path: '/OrganizationManagement',
        name: 'OrganizationManagement',
        component: () => import('@/views/Homepage/OrganizationManagement.vue'),
      },
      {
        path: '/UserManagement',
        name: 'UserManagement',
        component: () => import('@/views/Homepage/UserManagement.vue'),
      },
      {
        path: '/Approvals',
        name: 'Approvals',
        component: () => import('@/views/Homepage/Approvals.vue'),
      },
      {
        path: '/PolicyManagement',
        name: 'PolicyManagement',
        component: () => import('@/views/Homepage/PolicyManagement.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
