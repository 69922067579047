import Axios from 'axios'
import qs from 'qs'

const PRE_URL = process.env.VUE_APP_API // 请求前缀（从环境变量里取）

/*
* 获取语言
* */
const getLang = () => {
  if (window) {
    if (localStorage.getItem('locale')) {
      return localStorage.getItem('locale')
    }
    localStorage.setItem('locale', 'en')
    return 'en'
  }
  return 'en'
}

/*
* 获取通用请求参数
* */
const getCommonsPrms = () => {
  return {

  }
}

/*
* get请求
* */
const axGet = (apiPath, params) => {
  params = params ? { ...getCommonsPrms(), ...params } : getCommonsPrms()
  return Axios.get(PRE_URL + apiPath, { params })
};

/*
* post请求
* */
const axPost = (apiPath, params) => {
  params = params ? { ...getCommonsPrms(), ...params } : getCommonsPrms()
  Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  params = qs.stringify(params)
  return Axios.post(PRE_URL + apiPath, params)
};

export { axGet, axPost, getLang }
