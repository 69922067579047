import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLang } from './helpers'
import zhTW from 'element-ui/lib/locale/lang/zh-TW'
import en from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  messages['en'] = { ...messages['en'], ...en }
  messages['zh-TW'] = { ...messages['zh-TW'], ...zhTW }
  // console.log(messages)
  return messages
}

export default new VueI18n({
  locale: getLang(),
  messages: loadLocaleMessages()
})
